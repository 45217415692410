import { createRouter, createWebHistory } from "vue-router";
import RoleEnums from "./enums";
import store from "./store";

const scrollBehavior = (to, from, savedPosition) => {
  if (savedPosition) {
    return savedPosition;
  }
  const position = {};
  if (to.matched.some((m) => m.meta.scrollToTop)) {
    position.x = 0;
    position.y = 0;
  }
  return position;
};

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  scrollBehavior,
  routes: [
    {
      path: "/",
      redirect: "/login",
      meta: {
        hideForAuth: true,
      },
    },
    {
      path: "/TestAnalysisUserReport",
      component: () => import(/* webpackChunkName: "upload" */ "./views/TestAnalysisUserReport"),
      name: "TestAnalysisUserReport",
      meta: {
        requiresAuth: true,
        scrollToTop: true,
        roles: [RoleEnums.AffiliationHead, RoleEnums.Faculty, RoleEnums.SchoolTeacher],
      },
    },
    {
      path: "/TestAnalysis",
      component: () => import(/* webpackChunkName: "test-analysis" */ "./views/TestAnalysis"),
      name: "TestAnalysis",
      meta: {
        requiresAuth: true,
        scrollToTop: true,
        roles: [RoleEnums.AffiliationHead, RoleEnums.Faculty, RoleEnums.SchoolTeacher],
      },
    },
    {
      path: "/StudentDashboard",
      component: () =>
        import(/* webpackChunkName: "student-dashboard" */ "./views/student/StudentDashboard"),
      name: "StudentDashboard",
      meta: {
        requiresAuth: true,
        scrollToTop: true,
        roles: [RoleEnums.Student, RoleEnums.DemoStudent, "Deactive Demo Student"],
      },
    },
    {
      path: "/SubjectCoverage",
      component: () => import(/* webpackChunkName: "subject-coverage" */ "./views/SubjectCoverage"),
      name: "SubjectCoverage",
      meta: {
        requiresAuth: true,
        scrollToTop: true,
        roles: [RoleEnums.Student, RoleEnums.DemoStudent, "Deactive Demo Student"],
      },
    },
    {
      path: "/AffiliationCreateUser",
      component: () => import("./views/AffiliationCreateUser"),
      name: "AffiliationCreateUser",
      meta: {
        requiresAuth: true,
        scrollToTop: true,
        roles: [RoleEnums.AffiliationHead],
      },
    },
    {
      path: "/EditUser",
      component: () => import("./views/AffiliationCreateUser"),
      name: "EditUser",
      meta: {
        requiresAuth: true,
        scrollToTop: true,
        roles: [RoleEnums.AffiliationHead],
      },
    },
    {
      path: "/AssignmentReport",
      component: () => import("./views/AssignmentReport"),
      name: "AssignmentReport",
      meta: {
        requiresAuth: true,
        scrollToTop: true,
        roles: [
          RoleEnums.Student,
          RoleEnums.DemoStudent,
          RoleEnums.AffiliationHead,
          RoleEnums.Faculty,
          RoleEnums.SchoolTeacher,
        ],
      },
    },
    {
      path: "/DoubtReport",
      component: () => import("./views/DoubtReport"),
      name: "DoubtReport",
      meta: {
        requiresAuth: true,
        scrollToTop: true,
        roles: [
          RoleEnums.Student,
          RoleEnums.DemoStudent,
          RoleEnums.AffiliationHead,
          RoleEnums.Faculty,
          RoleEnums.SchoolTeacher,
        ],
      },
    },
    {
      path: "/EvaluateSubjectiveQuestions",
      component: () => import("./views/EvaluateSubjectiveQuestions"),
      name: "EvaluateSubjectiveQuestions",
      meta: {
        requiresAuth: true,
        scrollToTop: true,
        roles: [
          RoleEnums.Student,
          RoleEnums.DemoStudent,
          RoleEnums.AffiliationHead,
          RoleEnums.Faculty,
          RoleEnums.SchoolTeacher,
        ],
      },
    },
    {
      path: "/Dashboard",
      component: () =>
        import(/* webpackChunkName: "test-analysis" */ "./views/student/StudentDashboard"),
      name: "Dashboard",
      meta: {
        requiresAuth: true,
        scrollToTop: true,
        roles: [
          RoleEnums.Student,
          RoleEnums.DemoStudent,
          RoleEnums.AffiliationHead,
          RoleEnums.Faculty,
          RoleEnums.SchoolTeacher,
        ],
      },
    },
    {
      path: "/BatchReport",
      component: () => import("./views/BatchReport"),
      name: "BatchReport",
      meta: {
        requiresAuth: true,
        scrollToTop: true,
        roles: [RoleEnums.AffiliationHead, RoleEnums.Faculty, RoleEnums.SchoolTeacher],
      },
    },
    {
      path: "/TeacherFeedBack",
      component: () => import("./views/TeacherFeedBack"),
      name: "TeacherFeedBack",
      meta: {
        requiresAuth: true,
        scrollToTop: true,
        roles: [
          RoleEnums.Student,
          RoleEnums.DemoStudent,
          RoleEnums.AffiliationHead,
          RoleEnums.Faculty,
          RoleEnums.SchoolTeacher,
        ],
      },
    },
    {
      path: "/ConductTest/:testUserId/:testType",
      component: () => import(/* webpackChunkName: "conduct-test" */ "./views/ConductTestNewUi"),
      name: "ConductTest",
      meta: {
        hideNavbar: true,
        requiresAuth: true,
        scrollToTop: true,
        roles: [RoleEnums.Student, RoleEnums.DemoStudent],
      },
      props: true,
    },
    {
      path: "/TutorHelpQuestionView",
      name: "TutorHelpQuestionView",
      component: () => import("./views/TutorHelpQuestionView.vue"),
      meta: {
        requiresAuth: true,
        scrollToTop: true,
        roles: [
          RoleEnums.Student,
          RoleEnums.DemoStudent,
          RoleEnums.Faculty,
          RoleEnums.SchoolTeacher,
        ],
      },
    },
    {
      path: "/TestReport/:testUserId/:testType",
      component: () =>
        import(/* webpackChunkName: "test-report" */ "./views/common/studentTestReport.vue"),
      name: "TestReport",
      meta: {
        requiresAuth: true,
        scrollToTop: true,
        roles: [
          RoleEnums.Student,
          RoleEnums.DemoStudent,
          RoleEnums.AffiliationHead,
          RoleEnums.Faculty,
          RoleEnums.SchoolTeacher,
        ],
      },
      props: true,
    },
    {
      path: "/MyProfile",
      component: () => import(/* webpackChunkName: "home" */ "./views/MyProfile"),
      name: "MyProfile",
      meta: {
        requiresAuth: true,
        scrollToTop: true,
        roles: [
          RoleEnums.Student,
          RoleEnums.DemoStudent,
          RoleEnums.AffiliationHead,
          RoleEnums.Faculty,
          RoleEnums.SchoolTeacher,
        ],
      },
    },
    {
      path: "/TeachingContent",
      component: () => import(/* webpackChunkName: "TeachingContent" */ "./views/TeachingContent"),
      name: "TeachingContent",
      meta: {
        requiresAuth: true,
        scrollToTop: true,
        roles: [RoleEnums.AffiliationHead, RoleEnums.Faculty, RoleEnums.SchoolTeacher],
      },
    },
    {
      path: "/NotAuthorized",
      component: () => import(/* webpackChunkName: "not-authorized" */ "./views/NotAuthorized"),
      name: "NotAuthorized",
      meta: {
        scrollToTop: true,
      },
    },
    {
      path: "/Manageuser",
      component: () => import("./views/Manageuser"),
      name: "Manageuser",
    },
    {
      path: "/sso",
      name: "SSO",
    },
    {
      path: "/EffortAnalysisNew",
      component: () =>
        import(/* webpackChunkName: "effort-analysis-new" */ "./views/EffortAnalysisNew"),
      name: "EffortAnalysisNew",
      meta: {
        requiresAuth: true,
        scrollToTop: true,
        roles: [RoleEnums.AffiliationHead, RoleEnums.Faculty, RoleEnums.SchoolTeacher],
      },
    },
    {
      path: "/Logout",
      name: "Logout",
    },
    {
      path: "/AffiliationDashboard",
      component: () => import(/* webpackChunkName: "dashboard" */ "./views/affiliation/dashboard"),
      name: "AffiliationDashboard",
      meta: {
        requiresAuth: true,
        scrollToTop: true,
        roles: [RoleEnums.AffiliationHead, RoleEnums.Faculty, RoleEnums.SchoolTeacher],
      },
    },
    {
      path: "/Batch",
      component: () => import(/* webpackChunkName: "dashboard" */ "./views/Batch"),
      name: "Batch",
      meta: {
        requiresAuth: true,
        scrollToTop: true,
        roles: [RoleEnums.AffiliationHead, RoleEnums.Faculty],
      },
    },
    {
      path: "/Student",
      component: () => import(/* webpackChunkName: "dashboard" */ "./views/Student"),
      name: "Student",
      meta: {
        requiresAuth: true,
        scrollToTop: true,
        roles: [RoleEnums.AffiliationHead, RoleEnums.Faculty, RoleEnums.SchoolTeacher],
      },
    },
    {
      path: "/CreateAssignment",
      component: () => import("./views/CreateAssignmentNewV1"),
      name: "CreateAssignment",
      meta: {
        requiresAuth: true,
        scrollToTop: true,
        roles: [RoleEnums.AffiliationHead, RoleEnums.Faculty, RoleEnums.SchoolTeacher],
      },
    },
    {
      path: "/StudentReport",
      component: () => import(/* webpackChunkName: "test-analysis" */ "./views/StudentReport"),
      name: "StudentReport",
      meta: {
        requiresAuth: true,
        scrollToTop: true,
        roles: [
          RoleEnums.Student,
          RoleEnums.DemoStudent,
          RoleEnums.AffiliationHead,
          RoleEnums.Faculty,
          RoleEnums.SchoolTeacher,
        ],
      },
    },
    {
      path: "/StudentDoubtReport",
      component: () => import(/* webpackChunkName: "test-analysis" */ "./views/StudentDoubtReport"),
      name: "StudentDoubtReport",
      meta: {
        requiresAuth: true,
        scrollToTop: true,
        roles: [
          RoleEnums.Student,
          RoleEnums.DemoStudent,
          RoleEnums.AffiliationHead,
          RoleEnums.Faculty,
          RoleEnums.SchoolTeacher,
        ],
      },
    },
    {
      path: "/InstituteTestResult",
      component: () =>
        import(/* webpackChunkName: "test-analysis" */ "./views/InstituteTestResult"),
      name: "InstituteTestResult",
      meta: {
        requiresAuth: true,
        scrollToTop: true,
        roles: [RoleEnums.AffiliationHead, RoleEnums.Faculty, RoleEnums.SchoolTeacher],
      },
    },
    {
      path: "/SelectInstituteTest",
      component: () =>
        import(/* webpackChunkName: "test-analysis" */ "./views/SelectInstituteTestNew"),
      name: "SelectInstituteTest",
      meta: {
        requiresAuth: true,
        scrollToTop: true,
        roles: [RoleEnums.Student, RoleEnums.DemoStudent],
      },
    },

    {
      path: "/AffiliationProfile",
      component: () => import("./views/AffiliationProfile"),
      name: " AffiliationProfile",
      meta: {
        requiresAuth: true,
        scrollToTop: true,
        roles: [RoleEnums.AffiliationHead],
      },
    },
    {
      path: "/LearnVideos",
      component: () => import(/* webpackChunkName: "test-analysis" */ "./views/LearnVideos"),
      name: "LearnVideos",
      meta: {
        requiresAuth: true,
        scrollToTop: true,
        roles: [RoleEnums.Student, RoleEnums.DemoStudent],
      },
    },
    {
      path: "/LearnConcept",
      component: () => import(/* webpackChunkName: "test-analysis" */ "./views/LearnConcept"),
      name: "LearnConcept",
      meta: {
        requiresAuth: true,
        scrollToTop: true,
        roles: [RoleEnums.Student, RoleEnums.DemoStudent],
      },
    },
    {
      path: "/LearnStudyMaterial",
      component: () => import(/* webpackChunkName: "test-analysis" */ "./views/LearnStudyMaterial"),
      name: "LearnStudyMaterial",
      meta: {
        requiresAuth: true,
        scrollToTop: true,
        roles: [RoleEnums.Student, RoleEnums.DemoStudent],
      },
    },
    {
      path: "/PracticeCoverage",
      component: () => import(/* webpackChunkName: "test-analysis" */ "./views/PracticeCoverage"),
      name: "PracticeCoverage",
      meta: {
        requiresAuth: true,
        scrollToTop: true,
        roles: [RoleEnums.Faculty, RoleEnums.AffiliationHead],
      },
    },
    {
      path: "/InstituteTestCoverage",
      component: () =>
        import(/* webpackChunkName: "test-analysis" */ "./views/InstituteTestCoverage"),
      name: "InstituteTestCoverage",
      meta: {
        requiresAuth: true,
        scrollToTop: true,
        roles: [RoleEnums.Faculty, RoleEnums.AffiliationHead, RoleEnums.SchoolTeacher],
      },
    },
    {
      path: "/PastWeekPractice",
      component: () => import(/* webpackChunkName: "test-analysis" */ "./views/PastWeekPractice"),
      name: "PastWeekPractice",
      meta: {
        requiresAuth: true,
        scrollToTop: true,
        roles: [RoleEnums.Faculty, RoleEnums.AffiliationHead, RoleEnums.SchoolTeacher],
      },
    },
    {
      path: "/InstituteTestPerformance",
      component: () =>
        import(/* webpackChunkName: "test-analysis" */ "./views/InstituteTestPerformance"),
      name: "InstituteTestPerformance",
      meta: {
        requiresAuth: true,
        scrollToTop: true,
        roles: [RoleEnums.Faculty, RoleEnums.AffiliationHead, RoleEnums.SchoolTeacher],
      },
    },
    {
      path: "/Homework",
      component: () => import(/* webpackChunkName: "test-analysis" */ "./views/Homework"),
      name: "Homework",
      meta: {
        requiresAuth: true,
        scrollToTop: true,
        roles: [RoleEnums.Student, RoleEnums.DemoStudent],
      },
    },
    {
      path: "/AddCustomQuestion",
      component: () => import(/* webpackChunkName: "test-analysis" */ "./views/AddCustomQuestion"),
      name: "AddCustomQuestion",
      meta: {
        requiresAuth: true,
        scrollToTop: true,
        roles: [RoleEnums.AffiliationHead, RoleEnums.Faculty],
      },
    },
    {
      path: "/MergeInstituteTest",
      component: () => import(/* webpackChunkName: "test-analysis" */ "./views/MergeInstituteTest"),
      name: "MergeInstituteTest",
      meta: {
        requiresAuth: true,
        scrollToTop: true,
        roles: [RoleEnums.AffiliationHead, RoleEnums.Faculty, RoleEnums.SchoolTeacher],
      },
    },
    {
      path: "/PracticeSession",
      component: () => import("./views/PracticeSession"),
      name: "PracticeSession",
      meta: {
        requiresAuth: true,
        scrollToTop: true,
        roles: [RoleEnums.Student, RoleEnums.DemoStudent],
      },
    },
    {
      path: "/PracticeHome",
      component: () => import(/* webpackChunkName: "test-analysis" */ "./views/PracticeHome"),
      name: "PracticeHome",
      meta: {
        requiresAuth: true,
        scrollToTop: true,
        roles: [RoleEnums.Student, RoleEnums.DemoStudent, "Deactive Demo Student"],
      },
    },
    {
      path: "/ActionInstituteTestNew",
      component: () => import("./views/affiliation/manageInstituteTest"),
      name: "ManageInstituteTest",
      meta: {
        requiresAuth: true,
        scrollToTop: true,
        roles: [RoleEnums.AffiliationHead, RoleEnums.Faculty, RoleEnums.SchoolTeacher],
      },
    },
    {
      path: "/CustomQuestionList",
      component: () =>
        import(/* webpackChunkName: "add-institute-test" */ "./views/CustomQuestionList"),
      name: "CustomQuestionList",
      meta: {
        requiresAuth: true,
        scrollToTop: true,
        roles: [RoleEnums.AffiliationHead, RoleEnums.Faculty],
      },
    },
    {
      path: "/subjectiveevaluation",
      name: "Subjective Evaluation",
      component: () => import("./views/SubjectiveEvaluation"),
      meta: {
        requiresAuth: true,
        scrollToTop: true,
        roles: [RoleEnums.AffiliationHead, RoleEnums.Faculty],
      },
    },
    {
      path: "/testevaluation",
      name: "Test Evaluation",
      component: () => import("./views/TestEvaluation"),
      meta: {
        requiresAuth: true,
        scrollToTop: true,
        roles: [RoleEnums.AffiliationHead, RoleEnums.Faculty, RoleEnums.SchoolTeacher],
      },
    },
    {
      path: "/StudentAssignmentReport",
      component: () => import("./views/StudentAssignmentReport"),
      name: "StudentAssignmentReport",
      meta: {
        requiresAuth: true,
        scrollToTop: true,
        roles: [RoleEnums.AffiliationHead, RoleEnums.Faculty, RoleEnums.SchoolTeacher],
      },
    },
    {
      path: "/DetailReport",
      component: () => import("./views/DetailReport"),
      name: "DetailReport",
      meta: {
        requiresAuth: true,
        scrollToTop: true,
        roles: [RoleEnums.AffiliationHead, RoleEnums.Faculty, RoleEnums.SchoolTeacher],
      },
    },
    {
      path: "/ViewStudentAssignmentReport",
      component: () => import("./views/ViewStudentAssignmentReport"),
      name: "ViewStudentAssignmentReport",
      meta: {
        requiresAuth: true,
        scrollToTop: true,
        roles: [
          RoleEnums.Student,
          RoleEnums.DemoStudent,
          RoleEnums.AffiliationHead,
          RoleEnums.Faculty,
          RoleEnums.SchoolTeacher,
        ],
      },
    },
    {
      path: "/FilterManualTest",
      component: () => import("./views/FilterManualTest"),
      name: "FilterManualTest",
      meta: {
        requiresAuth: true,
        scrollToTop: true,
        roles: [RoleEnums.AffiliationHead, RoleEnums.Faculty],
      },
    },
    {
      path: "/PracticeSessionNewReport/:UserTestSessionId",
      component: () => import("./views/PracticeSessionNewReport"),
      name: "PracticeSessionNewReport",
      meta: {
        requiresAuth: true,
        scrollToTop: true,
        roles: [
          RoleEnums.Student,
          RoleEnums.DemoStudent,
          RoleEnums.AffiliationHead,
          RoleEnums.Faculty,
          RoleEnums.SchoolTeacher,
        ],
      },
    },
    {
      path: "/UploadLater",
      component: () => import("./views/UploadLater"),
      name: "Upload Later",
      meta: {
        requiresAuth: true,
        scrollToTop: true,
        roles: [RoleEnums.Student, RoleEnums.DemoStudent],
      },
    },
    {
      path: "/CreateInstituteTest",
      component: () => import("./views/CreateInstituteTest"),
      name: "CreateInstituteTest",
      meta: {
        requiresAuth: true,
        scrollToTop: true,
        roles: [RoleEnums.AffiliationHead, RoleEnums.Faculty, RoleEnums.SchoolTeacher],
      },
    },
    {
      path: "/EditInstituteTestNew/:testId",
      component: () => import("./views/EditInstituteTestV1"),
      name: "EditInstituteTestNew",
      meta: {
        requiresAuth: true,
        scrollToTop: true,
        roles: [RoleEnums.AffiliationHead, RoleEnums.Faculty, RoleEnums.SchoolTeacher],
      },
    },
    {
      path: "/ManualTestPreview",
      component: () => import("./views/ManualTestPreview"),
      name: "ManualTestPreview",
      meta: {
        requiresAuth: true,
        scrollToTop: true,
        roles: [RoleEnums.AffiliationHead, RoleEnums.Faculty, RoleEnums.SchoolTeacher],
      },
    },
    {
      path: "/AutomaticTestPreview",
      component: () => import("./views/AutomaticQuesGenerationComponent"),
      name: "AutomMatic",
      meta: {
        requiresAuth: true,
        scrollToTop: true,
        roles: [RoleEnums.AffiliationHead, RoleEnums.Faculty, RoleEnums.SchoolTeacher],
      },
    },
    {
      path: "/AssignmentListing",
      component: () => import("./views/AssignmentListing"),
      name: "AssignmentListing",
      meta: {
        requiresAuth: true,
        scrollToTop: true,
        roles: [RoleEnums.AffiliationHead, RoleEnums.Faculty, RoleEnums.SchoolTeacher],
      },
    },
    {
      path: "/ReportList",
      component: () => import(/* webpackChunkName: "report-list" */ "./views/ReportList"),
      name: "ReportList",
      meta: {
        requiresAuth: true,
        scrollToTop: true,
        roles: [RoleEnums.AffiliationHead, RoleEnums.Faculty, RoleEnums.SchoolTeacher],
      },
    },
    {
      props: true,
      path: "/TestAnalysisReportNew",
      component: () =>
        import(/* webpackChunkName: "test-analysis-report-new" */ "./views/TestAnalysisReportNew"),
      name: "TestAnalysisReportNew",
      meta: {
        requiresAuth: true,
        scrollToTop: true,
        roles: [RoleEnums.AffiliationHead, RoleEnums.Student, RoleEnums.DemoStudent],
      },
    },
    {
      props: true,
      path: "/TestMarksheet",
      component: () => import(/* webpackChunkName: "test-marksheet" */ "./views/TestMarksheet"),
      name: "TestMarksheet",
      meta: {
        requiresAuth: true,
        scrollToTop: true,
        roles: [RoleEnums.AffiliationHead, RoleEnums.Faculty, RoleEnums.SchoolTeacher],
      },
    },
    {
      path: "/AffiliationDetails",
      component: () => import("./views/AffiliationDetails"),
      name: "AffiliationDetails",
      meta: {
        requiresAuth: true,
        scrollToTop: true,
        roles: [RoleEnums.AffiliationHead],
      },
    },
    {
      path: "/CustomReports",
      component: () => import("./views/CustomReports"),
      name: "CustomReports",
      meta: {
        requiresAuth: true,
        scrollToTop: true,
        roles: [RoleEnums.AffiliationHead, RoleEnums.Faculty, RoleEnums.SchoolTeacher],
      },
    },
    {
      path: "/PracticeHistory",
      component: () => import(/* webpackChunkName: "add-student-test" */ "./views/PracticeHistory"),
      name: "PracticeHistory",
      meta: {
        requiresAuth: true,
        scrollToTop: true,
        roles: [RoleEnums.Student, RoleEnums.DemoStudent, "Deactive Demo Student"],
      },
    },
    {
      path: "/TutorHelpNew",
      component: () => import(/* webpackChunkName: "add-student-test" */ "./views/TutorHelpNew"),
      name: "TutorHelpNew",
      meta: {
        requiresAuth: true,
        scrollToTop: true,
        roles: [RoleEnums.Student, RoleEnums.DemoStudent],
      },
    },
    {
      path: "/ReviseQuestionsNew",
      component: () =>
        import(/* webpackChunkName: "add-student-test" */ "./views/ReviseQuestionsNew"),
      name: "ReviseQuestionsNew",
      meta: {
        requiresAuth: true,
        scrollToTop: true,
        roles: [RoleEnums.Student, RoleEnums.DemoStudent],
      },
    },
    {
      path: "/QuestionView",
      component: () => import(/* webpackChunkName: "add-student-test" */ "./views/QuestionView"),
      name: "QuestionView",
      meta: {
        requiresAuth: true,
        scrollToTop: true,
        roles: [RoleEnums.Student, RoleEnums.DemoStudent],
      },
    },
    {
      path: "/SubjectiveQuestionFeedback",
      component: () =>
        import(/* webpackChunkName: "test-analysis" */ "./views/SubjectiveQuestionFeedback"),
      name: "SubjectiveQuestionFeedback",
      meta: {
        requiresAuth: true,
        scrollToTop: true,
        roles: [RoleEnums.Student, RoleEnums.DemoStudent],
      },
    },
    {
      path: "/AddFaculty",
      component: () => import("./views/AddFaculty"),
      name: "AddFaculty",
      meta: {
        requiresAuth: true,
        scrollToTop: true,
        roles: [RoleEnums.AffiliationHead],
      },
    },
    {
      path: "/AddFacultyBatch",
      component: () => import("./views/AddFacultyBatch"),
      name: "AddFacultyBatch",
      meta: {
        requiresAuth: true,
        scrollToTop: true,
        roles: [RoleEnums.AffiliationHead],
      },
    },
    {
      path: "/UserListing",
      component: () => import("./views/affiliation/roleWiseUserListing"),
      name: "RoleWiseUserListing",
      meta: {
        requiresAuth: true,
        scrollToTop: true,
        roles: [RoleEnums.AffiliationHead, RoleEnums.Faculty, RoleEnums.SchoolTeacher],
      },
    },
    {
      path: "/StudyMaterialNew",
      component: () => import("./views/StudyMaterialV1"),
      name: "StudyMaterialV1",
      meta: {
        requiresAuth: true,
        scrollToTop: true,
        roles: [RoleEnums.AffiliationHead, RoleEnums.Faculty, RoleEnums.SchoolTeacher],
      },
    },
    {
      path: "/CoachingVideoNew",
      component: () => import("./views/CoachingVideoNewV1"),
      name: "CoachingVideoNewV1",
      meta: {
        requiresAuth: true,
        scrollToTop: true,
        roles: [RoleEnums.AffiliationHead, RoleEnums.Faculty, RoleEnums.SchoolTeacher],
      },
    },
    {
      props: true,
      path: "/TestAnalysisQuesWise",
      component: () => import("./views/TestAnalysisQuesWise"),
      name: "TestAnalysisQuesWise",
      meta: {
        requiresAuth: true,
        scrollToTop: true,
        roles: [RoleEnums.AffiliationHead, RoleEnums.Faculty, RoleEnums.SchoolTeacher],
      },
    },
    {
      path: "/WeeklyAssessment",
      component: () => import("./views/WeeklyAssessment"),
      name: "WeeklyAssessment",
      meta: {
        requiresAuth: true,
        scrollToTop: true,
        roles: [RoleEnums.AffiliationHead, RoleEnums.Faculty, RoleEnums.SchoolTeacher],
      },
    },
    {
      path: "/:pathMatch(.*)*",
      name: "not-found",
      component: () => import("./views/PageNotFound"),
    },
  ],
});

function resetLocalStorage(slToken) {
  localStorage.setItem("SLToken", slToken);
  localStorage.removeItem("DashboardData");
  localStorage.removeItem("TeacherDashboardData");
  localStorage.removeItem("DashboardRefreshTime");
  localStorage.removeItem("practiceSelectedSubjectData");
  localStorage.removeItem("PracticeSessionRefreshData");
  localStorage.removeItem("testQuestionList");
  localStorage.removeItem("isPastYear");
  localStorage.removeItem("isDppSession");
  localStorage.removeItem("pastYearQuestionIndex");
  localStorage.removeItem("isSubjective");
  localStorage.removeItem("UserTestSessionId");
}

router.beforeEach((to, from, next) => {
  if (to.name === "not-found") {
    window.parent.postMessage(`${JSON.stringify(to)}`, "*");
    return;
  }

  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (store.getters.loggedIn) {
      const roles = to.meta.roles || [];
      if (roles.includes(store.getters.user.role)) {
        next();
        window.parent.postMessage(`${JSON.stringify(to)}`, "*");
      } else {
        next({
          name: "NotAuthorized",
        });
      }
    } else {
      next();
    }
  } else {
    if (to.name === "SSO") {
      if (to.query.token && to.query.redirectTo) {
        resetLocalStorage(to.query.token);
        if (to.query.ThirdPartySource) {
          localStorage.setItem("ThirdPartySource", to.query.ThirdPartySource);
        }
        window.location = to.query.redirectTo;
      } else if (!to.query.token) {
        resetLocalStorage(to.query.token);
        window.location = `${process.env.VUE_APP_SL_URL}/login`;
      }
    }
    next();
  }
});

router.isReady().then(() => {
  store.dispatch("routerReady");
});

export default router;
