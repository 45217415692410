import axios from "axios";

export default class Doubtapi {
  static init(router) {
    this.router = router;
    axios.interceptors.request.use(
      (config) => {
        const originalRequest = config;
        let token = "";
        if (localStorage && localStorage.getItem("SLToken")) {
          token = localStorage.getItem("SLToken") || "";
        }
        originalRequest.headers.Authorization = token ? `Bearer ${token}` : "";
        return Promise.resolve(originalRequest);
        // return Promise.resolve(config);
      },
      (error) => Promise.reject(error),
    );

    // Add a response interceptor
    axios.interceptors.response.use(
      (response) => response,
      (error) => {
        console.log("error response", error);
        if (error.response.status === 401) {
          console.log("redirect to login");
          window.location = `${process.env.VUE_APP_SL_URL}/Login.aspx`;
        }
        return Promise.reject(error);
      },
    );
  }

  static async getChapterNotes(data, success, failure) {
    await axios
      .get(`${process.env.VUE_APP_DATA_API_URL}/learn/get-chapter-notes`, { params: data })
      .then(
        (response) => {
          if (success) {
            success(response.data);
          }
        },
        (error) => {
          if (failure) {
            failure(error);
            console.log(error);
          }
        },
      );
  }

  static async getAllCity(data, success, failure) {
    await axios.get(`${process.env.VUE_APP_DATA_API_URL}/common/get-all-cities`).then(
      (response) => {
        if (success) {
          success(response.data);
        }
      },
      (error) => {
        if (failure) {
          failure(error);
          console.log(error);
        }
      },
    );
  }

  static async getAllState(data, success, failure) {
    await axios.get(`${process.env.VUE_APP_DATA_API_URL}/common/get-all-states`).then(
      (response) => {
        if (success) {
          success(response.data);
        }
      },
      (error) => {
        if (failure) {
          failure(error);
          console.log(error);
        }
      },
    );
  }

  static async getConceptVideos(data, success, failure) {
    await axios
      .get(`${process.env.VUE_APP_DATA_API_URL}/learn/get-concept-videos-for-chapter`, {
        params: data,
      })
      .then(
        (response) => {
          if (success) {
            success(response.data);
          }
        },
        (error) => {
          if (failure) {
            failure(error);
            console.log(error);
          }
        },
      );
  }

  static async getExampleVideos(data, success, failure) {
    await axios
      .get(`${process.env.VUE_APP_DATA_API_URL}/learn/get-example-videos-for-chapter`, {
        params: data,
      })
      .then(
        (response) => {
          if (success) {
            success(response.data);
          }
        },
        (error) => {
          if (failure) {
            failure(error);
            console.log(error);
          }
        },
      );
  }

  static async getInstituteVideos(data, success, failure) {
    await axios
      .get(`${process.env.VUE_APP_DATA_API_URL}/learn/get-affiliation-videos-for-chapter`, {
        params: data,
      })
      .then(
        (response) => {
          if (success) {
            success(response.data);
          }
        },
        (error) => {
          if (failure) {
            failure(error);
            console.log(error);
          }
        },
      );
  }

  static async getTopics(data, success, failure) {
    await axios
      .get(`${process.env.VUE_APP_DATA_API_URL}/learn/get-concept-clusters`, { params: data })
      .then(
        (response) => {
          if (success) {
            success(response.data);
          }
        },
        (error) => {
          if (failure) {
            failure(error);
            console.log(error);
          }
        },
      );
  }

  static async getConcept(data, success, failure) {
    await axios
      .get(`${process.env.VUE_APP_DATA_API_URL}/learn/get-concepts`, { params: data })
      .then(
        (response) => {
          if (success) {
            success(response.data);
          }
        },
        (error) => {
          if (failure) {
            failure(error);
            console.log(error);
          }
        },
      );
  }

  static async practicesessionsubmitapi(data, success, failure) {
    await axios
      .post(
        `${process.env.VUE_APP_DOUBTS_API_URL}/api/practice/usertest-session/submit-question`,
        data,
      )
      .then(
        (response) => {
          if (success) {
            success(response.data);
          }
        },
        (error) => {
          if (failure) {
            failure(error);
            console.log(error);
          }
        },
      );
  }

  static async UserSessionErrorReport(data, success, failure) {
    await axios
      .post(
        `${process.env.VUE_APP_DOUBTS_API_URL}/api/practice/usertest-session/tag-question`,
        data,
      )
      .then(
        (response) => {
          if (success) {
            success(response);
          }
        },
        (error) => {
          if (failure) {
            failure(error);
            console.log(error);
          }
        },
      );
  }

  static async getPracticeDashboardList(data, success, failure) {
    await axios
      .get(
        `${process.env.VUE_APP_DOUBTS_API_URL}/api/practice/dashboard/GetPracticeDashBoardSessionsList`,
        { params: data },
      )
      .then(
        (response) => {
          if (success) {
            success(response.data);
          }
        },
        (error) => {
          if (failure) {
            failure(error);
            console.log(error);
          }
        },
      );
  }

  static getSession(data, success, failure) {
    axios.get(`${process.env.VUE_APP_DOUBTS_API_URL}/api/practice/institute-user/get-session`).then(
      (response) => {
        if (success) {
          success(response.data);
        }
      },
      (error) => {
        if (failure) {
          failure(error);
          console.log(error);
        }
      },
    );
  }

  static async getSessionReport(data, success, failure) {
    await axios
      .get(`${process.env.VUE_APP_DOUBTS_API_URL}/api/practice/user-report/get-report-data`, {
        params: data,
      })
      .then(
        (response) => {
          if (success) {
            success(response.data);
          }
        },
        (error) => {
          if (failure) {
            failure(error);
            console.log(error);
          }
        },
      );
  }

  static async getAffiliationProfile(affiliationId, success, failure) {
    await axios
      .get(
        `${process.env.VUE_APP_DOUBTS_API_URL}/api/practice/institute-account/get-affiliation-profile?affiliationProfileId=${affiliationId}`,
      )
      .then(
        (response) => {
          if (success) {
            success(response.data);
          }
        },
        (error) => {
          if (failure) {
            failure(error);
            console.log(error);
          }
        },
      );
  }

  static async updateAffiliationProfile(data, success, failure) {
    await axios
      .post(
        `${process.env.VUE_APP_DOUBTS_API_URL}/api/practice/institute-account/update-affiliation-profile`,
        data,
      )
      .then(
        (response) => {
          if (success) {
            success(response.data);
          }
        },
        (error) => {
          if (failure) {
            failure(error);
            console.log(error);
          }
        },
      );
  }

  static async InstituteAccountLoginCredits(data, success, failure) {
    await axios
      .get(`${process.env.VUE_APP_DOUBTS_API_URL}/api/practice/institute-account/Login-credit`)
      .then(
        (response) => {
          if (success) {
            success(response.data);
          }
        },
        (error) => {
          if (failure) {
            failure(error);
            console.log(error);
          }
        },
      );
  }

  static async whiteListVideo(data, success, failure) {
    await axios
      .put(`${process.env.VUE_APP_DOUBTS_API_URL}/api/practice/institute/whitelist-video`, data)
      .then(
        (response) => {
          if (success) {
            success(response.data);
          }
        },
        (error) => {
          if (failure) {
            failure(error);
            console.log(error);
          }
        },
      );
  }

  static async getPaymentPakage(success, failure) {
    await axios.get(`${process.env.VUE_APP_DOUBTS_API_URL}/api/payment-package`).then(
      (response) => {
        if (success) {
          success(response.data);
        }
      },
      (error) => {
        if (failure) {
          failure(error);
          console.log(error);
        }
      },
    );
  }

  static async updateUserPassword(data, success, failure) {
    await axios
      .patch(
        `${process.env.VUE_APP_DOUBTS_API_URL}/api/user-account/update-passsword-affiliation`,
        data,
      )
      .then(
        (response) => {
          if (success) {
            success(response.data);
          }
        },
        (error) => {
          if (failure) {
            failure(error);
            console.log(error);
          }
        },
      );
  }

  static async updateAffiliationProfileLogo(data, success, failure) {
    await axios
      .post(
        `${process.env.VUE_APP_DOUBTS_API_URL}/api/practice/institute-account/update-affiliation-profile-logo`,
        data,
      )
      .then(
        (response) => {
          if (success) {
            success(response.data);
          }
        },
        (error) => {
          if (failure) {
            failure(error);
            console.log(error);
          }
        },
      );
  }

  static createUser(data, success, failure) {
    axios
      .post(`${process.env.VUE_APP_DOUBTS_API_URL}/api/practice/institute-user/create`, data)
      .then(
        (response) => {
          if (success) {
            success(response.data);
          }
        },
        (error) => {
          if (failure) {
            failure(error);
            console.log(error);
          }
        },
      );
  }

  static bulkCreateUser(data, success, failure) {
    axios
      .post(`${process.env.VUE_APP_DOUBTS_API_URL}/api/practice/institute-user/bulk-create`, data)
      .then(
        (response) => {
          if (success) {
            success(response.data);
          }
        },
        (error) => {
          if (failure) {
            failure(error);
            console.log(error);
          }
        },
      );
  }
}
